<template>
  <div class="college">
    <div class="top">
      <el-row class="school_info">
        <el-col :span="4">
          <img :src="school.logo" alt="" style="
              width: 120px;
              position: relative;
              border-radius: 100px;
              top: 31px;
              left: 70px;
            " />
        </el-col>
        <el-col :span="20" style="padding-left: 40px">
          <el-row style="margin-top: 10px">
            <el-col :span="24">
              <span style="font-size: 18px; padding-right: 30px">{{
          school.name
        }}</span>
              <span style="padding-right: 30px">{{ school.address }}</span>
              <span style="color: red"><i class="iconfont icon-hot" />{{ school.school_hot }}</span>
            </el-col>
          </el-row>
          <el-row style="margin: 20px 0">
            <el-col :span="24">
              <span style="
                  border: 1px solid rgb(255 255 255 / 24%);
                  margin-right: 3px;
                  padding: 2px 5px;
                ">
                {{ school.degree == 0 ? "本科" : "专科" }}
              </span>
              <span style="
                  border: 1px solid rgb(255 255 255 / 24%);
                  margin-right: 3px;
                  padding: 2px 5px;
                ">
                {{ school.school_nature }}
              </span>
              <span style="
                  border: 1px solid rgb(255 255 255 / 24%);
                  margin-right: 3px;
                  padding: 2px 5px;
                ">
                {{ school.school_type }}
              </span>
              <span v-for="(item, i) in school.school_tip_id" :key="i" style="
                  border: 1px solid rgb(255 255 255 / 24%);
                  margin-right: 3px;
                  padding: 2px 5px;
                ">
                {{ item }}
              </span>
              <span v-if="school.sg_type" style="
                  border: 1px solid rgb(255 255 255 / 24%);
                  margin-right: 3px;
                  padding: 2px 5px;
                ">
                {{ school.sg_type }}
              </span>
              <span v-if="school.sg_specialty" style="
                  border: 1px solid rgb(255 255 255 / 24%);
                  margin-right: 3px;
                  padding: 2px 5px;
                ">
                {{ school.sg_specialty }}
              </span>
              <!-- <span class="guanzhu">+关注</span> -->
            </el-col>
          </el-row>
          <el-row style="margin: 20px 0">
            <el-col :span="24">
              <a v-if="school.school_url" :href="school.school_url" target="_blank" class="link">官方网站</a>
              <a v-if="school.school_admissions_url" :href="school.school_admissions_url" target="_blank"
                class="link">招生网站</a>
              <a v-if="school.school_panoramic" :href="school.school_panoramic" target="_blank" class="link">全景校园</a>
            </el-col>
          </el-row>
          <el-row>
            <span>学校地址: {{ school.full_address }}</span><span style="margin-left: 15px">
              咨询电话：{{ school.phone }}</span>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <div class="page">
      <div class="nav">
        <span @click="choseNav(0)" :class="{ cat: this.cat == 0 }">院校概况</span>
        <span @click="choseNav(1)" :class="{ cat: this.cat == 1 }">招生章程</span>
        <span @click="choseNav(2)" :class="{ cat: this.cat == 2 }">院校分数线</span>
        <span @click="choseNav(3)" :class="{
          cat: this.cat == 3,
          tip: school.score_update == 1,
          tips: school.score_update == 1 && this.cat == 3
        }">专业分数线</span>
        <span @click="choseNav(4)" :class="{ cat: this.cat == 4 }">招生计划</span>
        <!-- <span @click="choseNav(5)" v-if="school.art_status && is_vip == 2" :class="{ cat: this.cat == 5 }">艺术体育分数</span> -->
        <span @click="choseNav(6)" :class="{ cat: this.cat == 6 }">提前批计划</span>
        <span @click="choseNav(7)" :class="{ cat: this.cat == 7 }">提前批分数线</span>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>  
export default {
  name: "College",
  data() {
    return {
      school_id: "",
      cat: 0,
      school: [],
      currentTabComponent: "CollegeInfo",
      is_vip: JSON.parse(localStorage.getItem('UserInfo')).is_vip,
    };
  },
  created() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    //获取学校信息
    this.$fecth
      .get("school/schoolIndex", { params: { id: this.$route.query.cid } })
      .then((res) => {
        this.school = res;
        if (res.school_id) {
          // 获取院校分数线
          this.school_id = res.school_id;
        }
      });
  },
  methods: {
    choseNav(e) {
      this.cat = e;
      switch (e) {
        case 0:
          this.$router
            .push({
              path: "/college/",
              query: {
                cid: this.$route.query.cid,
                sid: this.school.school_id,
                degree: this.$route.query.degree,
              },
            })
            .catch(() => {
              console.log("重复点击按钮");
            });
          return;
        case 1:
          this.$router
            .push({
              path: "/college/schoolNews",
              query: {
                cid: this.$route.query.cid,
                sid: this.school.school_id,
                degree: this.$route.query.degree,
              },
            })
            .catch(() => {
              console.log("重复点击按钮");
            });
          return;
        case 2:
          this.$router
            .push({
              path: "/college/collegeScoreLine",
              query: {
                cid: this.$route.query.cid,
                sid: this.school.school_id,
                degree: this.$route.query.degree,
                child_school_id: this.school.child_school_id,
              },
            })
            .catch(() => {
              console.log("重复点击按钮");
            });
          return;
        case 3:
          this.$router
            .push({
              path: "/college/SpecialtyScore",
              query: {
                cid: this.$route.query.cid,
                sid: this.school.school_id,
                degree: this.$route.query.degree,
                child_school_id: this.school.child_school_id,
              },
            })
            .catch(() => {
              console.log("重复点击按钮");
            });
          return;
        case 4:
          this.$router
            .push({
              path: "/college/getPlan",
              query: {
                cid: this.$route.query.cid,
                sid: this.school.school_id,
                degree: this.$route.query.degree,
              },
            })
            .catch(() => {
              console.log("重复点击按钮");
            });
          return;
        case 5:
          this.$router
            .push({
              path: "/college/artScore",
              query: {
                cid: this.$route.query.cid,
                sid: this.school.school_id,
                degree: this.$route.query.degree,
              },
            })
            .catch(() => {
              console.log("重复点击按钮");
            });
          return;
        case 6:
          this.$router
            .push({
              path: "/college/otherBatch",
              query: {
                cid: this.$route.query.cid,
                sid: this.school.school_id,
                degree: this.$route.query.degree,
              },
            })
            .catch(() => {
              console.log("重复点击按钮");
            });
          return;
        case 7:
          this.$router
            .push({
              path: "/college/otherBatchScore",
              query: {
                cid: this.$route.query.cid,
                sid: this.school.school_id,
                degree: this.$route.query.degree,
              },
            })
            .catch(() => {
              console.log("重复点击按钮");
            });
          return;
        default:
          return;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.college {
  .top {
    background: #443fa3;
    min-height: 216px;

    .school_info {
      width: 1200px;
      margin: 0 auto;
      padding-top: 20px;
      color: #ffffff;

      a {
        display: inline-block;
        color: #0d55bc;
        text-decoration: none;
        background: white;
        height: 27px;
        line-height: 27px;
        padding: 0 10px;
        border-radius: 30px;
        margin: 0px 7px;
      }
    }
  }

  .guanzhu {
    float: right;
    position: relative;
    top: 10px;
    right: 40px;
  }

  .nav {
    font-size: 18px;
    background: #eef2f7;
    margin-top: 7px;

    span {
      display: inline-block;
      border-right: 1px solid #dfdfdf;
      padding: 16px 28px;

      &:hover {
        color: black;

        background: #dfe5e7;
      }
    }

    .cat {
      color: white;
      background: #519cea;
    }
  }
}

.tip {
  background: url("./../../assets/img/tip_new.png") no-repeat !important;
  background-position: right top !important;
}

.tips {
  background: #519cea url("./../../assets/img/tip_new.png") no-repeat !important;
  background-position: right top !important;
}
</style>